import React from 'react';
import { Button } from 'antd-mobile';
import { useHistory } from 'react-router-dom';

function Introduction() {
  const history = useHistory();
  return (
    <div className="introduction">
      <div className="header" />
      <div className="body">
        <h1>前言</h1>
        <p>为贯彻上海市委、市政府全力打响“上海服务、上海制造、上海购物、上海文化”品牌的四个《三年行动计划》；努力贯彻中央和市委关于推进品牌战略的工作要求，进一步提升产业园区“区域品牌综合能力”，树立品牌园区的示范和标杆；发挥品牌园区优秀掌门人引领效应，推进园区和企业，以“名家”为纽带，以“名园”为支撑，集中力量打造具有主导产业集聚、产业特色鲜明、影响力和辨识度都很高的“产业集群区域品牌”。推动上海园区品牌在自媒体时代的品牌展示和品牌传播，创响上海区域品牌、企业品牌、城市品牌注入移动互联网时代的新活力，在上海市经济和信息化委员会、上海市商务委员会、上海市知识产权局领导指导下；由上海市工业经济联合会（上海市经济团体联合会）、上海市商业联合会、上海市现代服务业联合会为支持单位，由上海市开发区协会、上海生产性服务业促进会、上海设计之都促进中心、上海市企业信息化促进中心、上海东方品牌文化发展促进中心为主办单位；由上海东方品牌文化发展促进中心为承办单位；由环球互通品牌服务有限公司为协办单位；由中新社上海分社为媒体支持；上海市企业服务云为特别支持；由上海园区品牌推选活动组委会组织2019年上海园区品牌推选活动的入选园区开展“上海园区品牌故事微视频展示活动”。</p>
        <p>参展园区分别获得“上海品牌示范园区”、“上海品牌园区”、“上海品牌特色园区”、“上海品牌园区优秀掌门人”等品牌称号，分属于“制造业型、服务业型、综合型、现代农业园区”四种类型的产业园区。</p>
        <p>参展作品通过生动的画面、情景、舞蹈、吟唱、解说词等形式，或展现园区大气恢弘、波澜壮阔的“前世今生”；或以小见大，娓娓道来园区跌宕起伏的“凤凰涅槃”；或现场采访、回顾展望园区铸造品牌的“奋斗历程”。</p>
        <p>“上海品牌示范园区” 、“上海品牌园区”的品牌故事，注重彰显园区（运营主体）“国际化、专业化、标准化、品牌化”的品牌战略；“管理创新、机制创新、成果创新”的品牌价值；“独特性、示范性、引领性” 的品牌成果。</p>
        <p>“上海品牌特色园区”的品牌故事，注重彰显园区（运营主体）“特色性、创新性、可持续发展性”的品牌战略、品牌价值、品牌成果。</p>
        <p>“上海品牌园区优秀掌门人”的品牌故事：注重彰显品牌园区（运营主体）优秀掌门人，在打造上海园区品牌中,发挥“产业领先、行业认可”的“名园、名企、名品、名家”品牌战略、品牌价值、品牌成果的示范和引领作用。</p>
        <p>上海园区品牌推选活动组委会，将组织参展园区的参展作品，在自媒体、互联网、主流媒体、论坛会、培训会、研讨沙龙等，开展线上展播，并择优推荐参赛等品牌宣传推介活动。</p>
        <p>参展微视频，是参展园区（运营主体）的原创作品，版权归属参展园区（运营主体）所有。</p>
        <p>所有参展作品发生的版权纠纷，均与上海园区品牌推选活动组委会无关。</p>
        <p>参展园区（运营主体），授权上海园区品牌推选活动组委会组织无偿使用和编辑的权利。</p>
        <p>“上海园区品牌故事微视频展示活动”，由上海园区品牌推选活动组委会办公室（非常设工作机构）负责策划、解释、组织和实施。</p>
        <p><strong>联系方式：</strong></p>
        <ul>
          <li>联系人：邵女士</li>
          <li>联系电话：18017928411</li>
          <li>邮箱：shaohz202@126.com</li>
        </ul>
        <p style={{ textAlign: 'right' }}>上海园区品牌推选活动组委会<br />2020年8月</p>
      </div>
      <div className="footer">
        <Button type="primary" onClick={() => history.push('/')}>返回首页</Button>
      </div>
    </div>
  );
}

export default Introduction;