import React from 'react';
import { Button } from 'antd-mobile';
import { useHistory } from 'react-router-dom';
import img_gold from './assets/img-gold@2x.jpg';
import img_silver from './assets/img-silver@2x.jpg';
import img_bronze from './assets/img-bronze@2x.jpg';

function Index() {
  const history = useHistory();
  return (
    <div className="index">
      <div className="body">
        <div className="preface">
          <div className="body">为贯彻上海市委、市政府全力打响“上海服务、上海制造、上海购物、上海文化”品牌的四个《三年行动计划》；努力贯彻中央和市委关于推进品牌战略的工作要求，进一步提升产业园区“区域品牌综合能力”，树立品牌园区的示范和标杆……</div>
          <div className="footer">
            <Button type="primary" onClick={() => history.push('/introduction')}>查看前言</Button>
          </div>
        </div>
        <div className="categories">
          <div className="item" onClick={() => history.push('/list/上海品牌示范园区')}>
            <img src={img_gold} alt="上海品牌示范园区" />
          </div>
          <div className="item" onClick={() => history.push('/list/上海品牌园区')}>
            <img src={img_silver} alt="上海品牌园区" />
          </div>
          <div className="item" onClick={() => history.push('/list/上海品牌特色园区')}>
            <img src={img_bronze} alt="上海品牌特色园区" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;