import React from 'react';
import { Tabs, Button } from 'antd-mobile';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import { filter, findIndex } from 'lodash';
import qs from 'qs';
import datasource, { tabs } from '../datasource';

function generateItems(title, type, history) {
  const criteria = { title };
  if (type && type !== '全部') {
    criteria.type = type;
  }
  return filter(datasource, criteria).map(item => (
    <div key={item.id} className="item" onClick={() => history.push({ pathname: `/details/${item.id}`, state: { back: history.location } })}>
      <div className="body">
        <div className="thumb">
          <img src={`//biaoxq.oss-cn-shanghai.aliyuncs.com/pps/logo-${item.id}.jpg`} alt="园区标识" />
        </div>
        <div className="type">{item.type}</div>
        <div className="description">{item.name}</div>
      </div>
    </div>
  ));
}

function List() {
  const location = useLocation();
  const history = useHistory();
  const { title } = useRouteMatch().params;
  if (!tabs[title]) {
    return null;
  }
  
  const { banner, types } = tabs[title];
  const initialPage = findIndex(types, { title: qs.parse(location.search.substring(1)).type });
  
  return (
    <div className="list">
      <div className="header">
        <img src={banner} alt={title} />
      </div>
      <div className="body">
        <Tabs tabs={ types } initialPage={ initialPage >= 0 ? initialPage : 0 } onChange={tab => history.replace({ search: `type=${tab.title}` })}>
          { types.map(tab => (
            <div key={tab.title} className="grid">
              { generateItems(title, tab.title, history) }
            </div>
          )) }
        </Tabs>
      </div>
      <div className="footer">
        <Button type="primary" onClick={() => history.push('/')}>返回首页</Button>
      </div>
    </div>
  );
}

export default List;