import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Details from './pages/Details';
import List from './pages/List';
import Introduction from './pages/Introduction';
import Index from './pages/Index';

function App() {
  return (
    <div className="app">
      <Switch>
        <Route path="/details/:id" component={Details} />
        <Route path="/list/:title" component={List} />
        <Route path="/introduction" component={Introduction} />
        <Route path="/" component={Index} />
      </Switch>
      <div className="footer">
        由<span className="highlight">环球互通品牌服务有限公司</span>提供技术支持
      </div>
    </div>
  );
}

export default App;
