import React, { useEffect } from 'react';
import { Button } from 'antd-mobile';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { find } from 'lodash';
import datasource from '../datasource';

function Details() {
  const { state } = useLocation();
  const history = useHistory();
  const { id } = useParams();
  const details = find(datasource, { id: parseInt(id) });
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  
  if (!details) {
    return null;
  }
  return (
    <div className="details">
      <div className="top">
        <video width="100%" height="200" playsInline src={`//biaoxq.oss-cn-shanghai.aliyuncs.com/ppp/mov-${details.id}.mp4`} controls="controls" />
      </div>
      <div className="header">
        <div className="logo">
          <img src={`//biaoxq.oss-cn-shanghai.aliyuncs.com/pps/logo-${details.id}.jpg`} alt="园区标识" />
        </div>
        <div className="description">
          <div className="name">{details.name}</div>
          <div className="title">{details.title}</div>
        </div>
      </div>
      <div className="body">
        <div className="photos">
          <img src={`//biaoxq.oss-cn-shanghai.aliyuncs.com/pps/photo-${details.id}.jpg`} alt="园区照片" />
        </div>
        <div className="content" dangerouslySetInnerHTML={{ __html: details.description }} />
        { details.extraContent ? (
          <div className="extra-content" dangerouslySetInnerHTML={{ __html: details.extraContent }} />
        ) : null }
        { details.extraVideo ? (
          <div className="extra-video">
            <video width="100%" height="200" playsInline src={`//biaoxq.oss-cn-shanghai.aliyuncs.com/ppp/${details.extraVideo}`} controls="controls" />
          </div>
        ) : null }
      </div>
      <div className="footer">
        <Button type="primary" onClick={() => state && state.back ? history.push(state.back) : history.push('/') }>{ state && state.back ? '返回列表' : '返回首页' }</Button>
      </div>
    </div>
  );
}

export default Details;