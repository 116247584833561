import banner_gold from './pages/assets/banner-gold@2x.jpg';
import banner_silver from './pages/assets/banner-silver@2x.jpg';
import banner_bronze from './pages/assets/banner-bronze@2x.jpg';

export const tabs = {
  '上海品牌示范园区': {
    banner: banner_gold,
    types: [ { title: '全部' }, { title: '制造业型' }, { title: '综合型' }, { title: '服务业型' }, { title: '现代农业' } ]
  },
  '上海品牌园区': {
    banner: banner_silver,
    types: [ { title: '全部' }, { title: '制造业型' }, { title: '服务业型' }, { title: '现代农业' } ]
  },
  '上海品牌特色园区': {
    banner: banner_bronze,
    types: [ { title: '服务业型' } ]
  }
};

export default [{
  id: 1,
  name: '上海金桥经济技术开发区',
  title: '上海品牌示范园区',
  type: '制造业型',
  description: '<p>上海金桥经济技术开发区是国家级开发区，其前身为1990年成立的“上海市金桥出口加工区开发公司”。上海金桥（集团）有限公司（以下简称“金桥集团”）是上海金桥经济经济技术开发区的开发建设运营主体，主要负责金桥经济技术开发区（自贸区金桥片区）、金桥综合保税区、金桥临港综合区（自贸区临港新片区）、金鼎天地、南汇工业园区等区域范围内的土地开发利用、基础设施建设、产业集群发展和城市功能配套等。已形成“1+4+X”的发展格局，确立了“彰显功能开发、提升产业能级、注重企业效益、铸造金桥品牌”的工作主线，致力于成为具有全球影响力的园区综合开发运营商。</p><p>金桥经济技术开发区先后获评亚洲十大最佳投资环境园区、国家生态工业示范园区、国家新型工业化示范基地、上海十大品牌、上海品牌示范园区、上海品牌园区、上海产业园区品牌开发建设运营机构、上海市文明单位、上海生产性服务业功能区、上海国家高技术服务产业园区、上海市首批服务业综合改革试点区域、上海市研发设计服务外包专业园区、上海市知识产权试点园、上海市节水工业园区、上海市绿化先进集体、上海文化产业园区—金桥网络文化产业基地、上海四新经济工业机器人产业基地等称号和资质。</p>'
}, {
  id: 2,
  name: '上海化学工业经济技术开发区',
  title: '上海品牌示范园区',
  type: '制造业型',
  description: '<p>上海化学工业经济技术开发区是国家级经济技术开发区，上海化学工业区的开发建设运营主体是上海化学工业区发展有限公司。位于杭州湾北岸，是以石油化工产品为主的专业开发区，建设形成以乙烯为龙头的循环经济产业链，以化工新材料为主导的特色产业集群，成为全国集聚知名跨国化工企业最多、主导产业能级高端、安全环保管理严格、循环经济水平领先的化工园区，被列为全国重点建设的七大石化产业基地之一。连续多年位列“中国化工园区20(30)强”榜首。</p><p>上海化学工业区先后获评为国家新型工业化产业示范基地、国家生态工业示范园区、全国循环经济工作先进单位、全国石油和化工行业责任关怀最佳组织单位、中国智慧化工园区试点示范单位、上海产业园区品牌开发建设运营机构、上海品牌示范园区、上海品牌园区等称号和资质。</p><p>上海化学工业区发展有限公司总经理张淳获评上海品牌园区优秀掌门人称号。</p>'
}, {
  id: 3,
  name: '上海临港产业区',
  title: '上海品牌示范园区',
  type: '制造业型',
  description: '<p>上海临港集团是上海市国资委下属唯一一家以产业园区投资、开发、经营和园区相关配套服务为主业的国有企业。临港新片区设立后，临港集团作为临港新片区开发建设的主体，在中国上海自由贸易试验区临港新片区的建设中承担重要使命。</p><p>临港集团一贯注重品牌建设工作，取得了较为显著的成效。2017、2018年，临港集团蝉联园区中国30强榜首，品牌知名度、美誉度、影响力日益增强。目前临港集团旗下拥有“临港”、“漕河泾”、“临港新业坊”等3个主要商标，并在本市15个区县建设了“临港”、“漕河泾”品牌园区以及“新业坊”项目。（“漕河泾”、“临港”分别于2009年、2013年成为上海市著名商标，“临港新业坊”设立于2015年）。</p><p>上海临港产业区先后获评国家新型工业化示范基地、上海品牌示范园区、上海产业园区品牌开发建设运营机构、上海品牌园区称号和资质。</p>'
}, {
  id: 4,
  name: '上海闵行经济技术开发区',
  title: '上海品牌示范园区',
  type: '制造业型',
  description: '<p>上海闵行经济技术开发区（简称闵行开发区）是1986年经国务院批准设立的首批国家级经济技术开发区，闵行开发区的开发建设、运营管理主体是上海闵行联合发展有限公司。其中闵行园区占地面积3.5平方公里，临港园区占地面积13.3平方公里，西区占地面积1.14平方公里。近年来，闵行开发区积极转变园区经济发展方式，坚持以先进制造业为立园之本，致力于打造高端制造、智能制造产业高地，积极融入上海科技创新中心、上海自贸区临港新片区、上海马桥人工智能创新实验区建设，是上海首批26个特色产业园区之一。闵行开发区积极推进园区存量资源的二次释放，推动低效企业“腾笼换鸟”，在园区内融入科创元素，从而提高了土地利用率，进一步盘活优化存量资产，提升了园区经济密度、产出强度，有力推动了园区高质量发展。</p><p>园区先后获评国家生态工业示范园区、上海品牌示范园区、上海产业园区品牌开发建设运营机构、上海品牌园区、上海市节水型工业园区、上海市质量和环境双优园区、上海市文明单位、上海市花园单位等称号和资质。</p><p>上海地产闵虹（集团）有限公司执行董事、总经理；上海闵行联合发展有限公司董事长冯晓明获评上海品牌园区优秀掌门人。</p>'
}, {
  id: 5,
  name: '上海莘庄工业区',
  title: '上海品牌示范园区',
  type: '制造业型',
  description: '<p>上海莘庄工业区是于1995年经上海市人民政府批准成立的市级工业园区，区域面积17.88平方公里。经过24年的开发建设，形成了“6+1”的主导产业格局：即机械及汽车零部件、重大装备、航空航天、电子信息、新材料及精细化工、生物医药以及生产性服务业，产业集聚度达80%。至2019年底，园区共有入驻企业783家，外资企业548家，世界500强企业52家，外资地区总部17家，研发中心96家，集聚了GE、大金、雅诗兰黛、三菱等国际知名品牌，上海电气、中国航天、中船重工等国内驰名商标。</p><p>园区先后获上海首家通过验收的国家生态工业示范园区、第四批国家新型工业化产业示范基地、国家第二批循环经济试点单位、上海品牌示范园区、首批“上海品牌园区”、上海产业园区品牌开发建设运营机构、上海市知识产权试点园区、上海市军民融合产业基地、上海市智能装备产业创新基地、上海智慧园区、上海知名品牌示范区和上海市先进制造业出口工业产品质量安全示范区、全国模范劳动关系和谐工业园区等称号和资质。</p>'
}, {
  id: 6,
  name: '上海市工业综合开发区',
  title: '上海品牌示范园区',
  type: '制造业型',
  description: '<p>上海市工业综合开发区，是东方美谷产业核心承载区。位于奉贤南桥新城的中西部，原名上海奉浦开发区，成立于1994年5月，1995年被列为市级工业区，2000年市工业投资公司入股后进行资产重组。2003年，经国务院批准设立国家级闵行出口加工区，2018年4月国务院批复上海闵行出口加工区转型为奉贤综合保税区。园区围绕“品牌的集聚与集聚区的品牌、园区建设和奉贤新城的建设、创新与转型、先进制造业和现代服务业”等“四个同步发展”；建设“东方美谷产业核心承载区及先进制造业核心区、新城产城融合的功能示范区、奉贤新经济发展的先行区、自贸区、张江及临港产业辐射承接区”等“四个区”的品牌战略，着力打造国内乃至亚洲规模最大的美丽健康产业集群，开启了从传统工业园区转型升级为新型城市社区的品牌经济发展征程。</p><p>截至2019年，园区实现纳税总额76.3亿元，规模以上工业产值424.7亿元。2019年，园区的美丽健康产业全年纳税32.5亿元，占园区税收比重的42.6%，占奉贤区美丽健康产业税收比重约70%。</p><p>园区先后获评国家级国家火炬计划上海奉贤输配电产业基地、国家工业生态示范园区、上海市首批新型工业化示范基地、上海市智慧园区试点单位、上海市“四新基地”试点、上海知名品牌创建示范区、上海市著名商标、上海优秀开发建设运营机构、上海品牌示范园区、上海品牌园区等称号和资质。</p>'
}, {
  id: 7,
  name: '上海枫泾工业区',
  title: '上海品牌示范园区',
  type: '制造业型',
  description: '<p>上海枫泾工业区是上海市人民政府批准的市级工业区，是国家火炬上海枫泾高端智能装备特色产业基地，是上海张江高新区金山园—枫泾分园，2018年合作共建的张江长三角科技城融入长三角一代化发展区域。枫泾工业园区认真落实打响上海“四大品牌”和“重振老字号品牌、打响新品牌实施方案”的品牌战略，坚持建设“一个老品牌产业园、一个科创小镇、一条老字号商业街、一个要素聚集平台-长三角路演中心、一座长三角科技城、一批新老自主品牌企业和品牌产品”；联动启动“上海老牌新品首发地、长三角老品牌振兴基地、长三角老字号产业联盟”。实现园区新品牌建设和枫泾老字号、老品牌振兴的融合发展的，努力建设上海制造品牌示范园区。</p><p>枫泾工业区先后获评上海品牌示范园区、上海品牌园区、上海市知识产权示范园区、上海市知名品牌示范区、上海品牌开发建设运营机构、上海科创园区专业委员会单位、长三角路演中心、科创小镇、枫泾商标品牌指导站等称号和资质。工业区己通过ISO9000和ISO14000体系认证。</p>'
}, {
  id: 8,
  name: '上海漕河泾新兴技术开发区',
  title: '上海品牌示范园区',
  type: '综合型',
  description: '<p>上海漕河泾新兴技术开发区开发区是上海市唯一同时具备国家级经开区、国家级高新区、国家级综保区三重功能的开发区，现规划面积14.28平方公里，是上海建设具有全球影响力科技创新中心的六大重要承载区之一。目前园区引进中外高科技企业及研发服务机构3600多家，84家世界500强跨国公司设立了139个高科技项目，形成了以电子信息为支柱产业，以新材料、生物医药、高端装备、汽车研发配套、环保新能源为重点产业，以现代服务业为支撑产业的“一五一”产业集群。</p><p>历经36年发展，“漕河泾”已成为产业地产开发运营优质品牌，象征着优良的投资环境、完善的服务体系、良好的科创氛围、精简高效的企业化运作机制。先后获评国家新型工业化产业示范基地 、国家海外高层次人才创新创业基地、国家知识产权服务业集聚发展示范区、 国家生态工业示范园区、国家知识产权示范园区、全国文明单位、上海产业园区品牌开发建设运营机构、上海品牌示范园区、上海品牌园区等称号和资质。</p>'
}, {
  id: 9,
  name: '上海奉贤生物科技园区',
  title: '上海品牌示范园区',
  type: '综合型',
  description: '<p>上海奉贤生物科技园区为东方美谷核心区。核心区占地面积5.95平方公里，其主导产业为美丽健康产业和生产性服务业。根据奉贤区委、区政府制定的“东方美谷”发展战略，园区确定了“1+1+X”的产业发展定位，通过合作、投资等渠道，紧紧围绕“八大中心”建设，不断健全配套美丽健康产业发展的产业支撑体系，助推产业健康快速发展。目前奉贤生物科技园区（东方美谷核心区）内已经落户了上海生物制品研究所、巴斯德研究所、伽蓝、莱士血液等一大批美丽健康重点企业。</p><p>奉贤生物科技园区（东方美谷核心区）先后获评上海国家生物产业基地、国家科技兴贸创新基地、国家级消费品标准化试点、上海市新药创制四新基地、上海生产性服务业功能区、上海市知名品牌创建示范区、上海市知识产权示范园区、上海市转型升级试点园区、上海市服务业综合改革试点、上海产业园区品牌开发建设运营机构、上海品牌示范园区、上海品牌园区等称号和资质。</p>'
}, {
  id: 10,
  name: '上海外高桥保税区',
  title: '上海品牌示范园区',
  type: '服务业型',
  description: '<p>上海外高桥集团股份有限公司是中国（上海）自由贸易试验区核心区域-外高桥保税区及其周边区域的开发运营主体。主要负责外高桥保税区及周边相关土地等国有资产的投资、经营和管理，以10平方公里的外高桥保税区规划用地和周边相关地块的房地产开发和经营为主业，承担着自贸试验区及周边区域的开发建设、招商稳商、功能推进和运营服务。外高桥保税区已经形成了以国际贸易、现代物流、先进制造和现代服务类为主导产业的实力雄厚的跨国企业群落，以及数量庞大且国际关联度高的客户群体。外高桥保税区已逐步发展成为集国际贸易、先进制造、转口贸易、保税仓储和商品展示等功能于一身的经济开放型区域。</p><p>园区先后中国建筑工程鲁班奖、全国模范职工之家、上海市文明单位、上海现代服务业联合会突出贡献奖、上海产业园区品牌开发建设运营机构、上海品牌示范园区、上海品牌园区等称号和资质。</p>'
}, {
  id: 11,
  name: '上海市市北高新技术服务业园区',
  title: '上海品牌示范园区',
  type: '服务业型',
  description: '<p>上海市市北高新技术服务业园区成立于1992年8月，总规划面积3.31平方公里，规划建筑面积550万平方米，位于静安区北部紧邻南北高架与中环。在静安区“一轴三带”发展战略中，是“中环两翼产城融合集聚带”的重要组成部分，已经成为了静安对接上海“全球卓越城市”“全球科创中心”建设的核心承载区。作为首个上海市大数据产业基地，市北园区被纳入《上海市城市总体规划2035》、《上海市产业地图》，将重点聚焦融合性数字产业，着力打造“中国大数据产业之都”、建设“中国创新型产业社区”。</p><p>园区先后获评国家高技术产业基地、国家新型工业化产业示范基地、全国文明单位、全国守合同重信用企业、国家级科技企业孵化器、全国知名品牌创建示范区、长江经济带国家级转型升级示范区、上海市海外高层次人才创新创业基地、上海市四新经济创新基地、上海市大数据产业基地、上海十佳生产性服务业功能区示范园区、上海市质量与环境双优单位、上海市“数字”园区、上海市花园单位、上海市文明单位、上海市著名商标、上海市知识产权示范园区、中国房地产公司品牌价值TOP10、上海市水利工程金奖、上海名牌、上海市企业文化建设示范基地、上海市先进基层党组织、上海市模范集体、中国证券市场2015年度龙鼎奖、上海市工商联基层组织工作十大创新等称号和资质。</p>'
}, {
  id: 12,
  name: '上海交通大学国家大学科技园',
  title: '上海品牌示范园区',
  type: '服务业型',
  description: '<p>上海交大科技园是2001年5月由科技部、教育部联合命名的全国首批国家大学科技园，2012年被科技部、教育部认定为A类（优秀）国家大学科技园，园区建有两个国家级科技企业孵化器和两个上海市科技企业孵化器。上海交大科技园有限公司是上海交通大学国家大学科技园的开发运营实体，从事科技园区开发运营、科技成果转化与科技企业培育、科技产业投资三大主要业务，是上海市高新技术企业，拥有上海市著名商标“慧谷创业”。“慧谷创业”作为全国孵化类园区为数甚少的著名品牌，在长三角地区已经形成一定的品牌效益，更是上海交通大学服务地方创新发展的一张靓丽名片。</p><p>交大科技园先后获评上海品牌园区、上海产业园区品牌开发建设运营机构、上海品牌示范园区、上海市创业孵化示范基地”以及上海孵化器三十周年表彰：获杰出成就孵化器奖、杰出成就人物奖、30年杰出贡献奖、优秀创业导师奖、孵化服务标兵奖、明星科创企业奖、新锐创业企业奖六项大奖。</p>'
}, {
  id: 13,
  name: '上海浦东软件园',
  title: '上海品牌示范园区',
  type: '服务业型',
  description: '<p>上海浦东软件园源起于1992年7月，由原国家信息产业部和上海市人民政府共同组建，上海浦东软件园股份有限公司为其运营主体。经过二十多年的建设和沉淀，上海浦东软件园现已拥有郭守敬园、祖冲之园、三林世博园、昆山园、新三林园五大园区。截至2019年12月，园区共拥有企业1700余家，从业人员4.5万人，园区软件和信息服务业实现经营收入超760亿元。</p><p>园区先后获评国家软件产业基地、国家软件出口基地、国家新型工业化基地、国家级科技企业孵化器、全球最佳服务外包园区——中国十强、亚洲最佳孵化器、上海市著名商标、上海市文明单位、上海品牌园区、上海产业园区品牌开发建设运营机构、上海品牌示范园区、上海市明星软件园（领先型）、上海市“四星”经济创新基地、上海市“四星级诚信企业创建单位”、浦东新区区长质量奖、浦东新区纳税突出贡献奖、浦东新区优秀青年突击队等称号和资质。</p>'
}, {
  id: 14,
  name: '上海国际化工生产性服务业功能区',
  title: '上海品牌示范园区',
  type: '服务业型',
  description: '<p>上海金石湾投资咨询有限公司是上海国际化工生产性服务业功能区（以下简称“金石湾功能区”）的建设运营机构，隶属于金山区山阳镇人民政府。公司主要负责园区的招商引资、企业政策扶持和园区内部服务和管理工作。</p><p>金石湾功能区秉承着“从心出发，助力园区企业发展”的理念，以化工品电子商务、化工技术研发、新材料及节能环保、环境技术与咨询服务、互联网产品及技术开发为主导产业，致力于打造一个集创业孵化投资、科技创新转化、产业精准培育和国际交流合作为一体的综合性产业园区。</p><p>金石湾功能区先后获评上海国家高技术服务产业重点培育园区、上海市科技企业孵化器、上海十佳生产性服务业功能区示范园区、上海市文明单位、上海市化工行业协会第五届理事会监事单位、上海市危险化学品行业协会金山分会、上海品牌示范园区、上海品牌园区、上海产业园区优秀开发建设运营机构等称号和资质。</p>',
  extraVideo: 'mov-14-extra.mp4'
}, {
  id: 15,
  name: '上海越界文化创意园区',
  title: '上海品牌示范园区',
  type: '服务业型',
  description: '<p>“越界”是由上海锦和商业经营管理股份有限公司运营和打造的文创园区品牌，是上海市文化创意产业园区运营管理领域的“著名商标”。锦和商业秉持“延续城市文脉，服务中国城市升级”的愿景，致力于将“越界”打造成为在中国文化创意产业园区中领先的服务品牌，践行“发现价值、创造价值、提升价值”的历史使命，营造商务、商业、文化创意三位一体的文化创意产业园区，创造属于文化创意人群的生活方式和平台。锦和商业于2020年4月登陆资本市场于上交所主板A股上市，股票代码603682。目前，其“越界”品牌项目已遍及上海、北京、杭州、南京、苏州，并将持续在中国一线城市及长三角地区加快开拓与发展的步伐。未来，将继续致力于中国城市产业升级，以文化创意园区为载体，助力中国城市更新。</p><p>越界文创园先后荣获卓越成就奖 、创意先锋奖、年度城市更新和既有建筑改造优秀运营商、十大城市更新最佳服务运营商、十大城市更新资产运营机构、上海品牌示范园区、上海产业园区品牌运营机构、上海品牌园区、上海市文化企业十强、首批上海市文化创意产业示范园区、上海首批12家智慧园区试点园区、上海创意产业集聚区最具潜力奖、诚信创建企业。2019年，越界文创园有10个项目获评上海市级文化创意产业园区、示范楼宇或示范空间等称号和资质。</p><p>上海锦和商业经营管理股份有限公司董事长郁敏珺获评上海品牌园区优秀掌门人。</p>'
}, {
  id: 16,
  name: '上海金山现代农业园区',
  title: '上海品牌示范园区',
  type: '现代农业',
  description: '<p>上海金山现代农业园区是国家级现代农业园区，是上海建设社会主义新农村的先行区和示范区，是上海对外展示社会主义新农村建设成果的重要窗口。2015年，建成上海市第一个郊野公园—廊下郊野公园。目前，正在打造长三角“田园五镇”现代农业的示范区与乡村振兴的先行区。金山现代农业园区与金山区廊下镇实行“镇和园区合一”的管理体制，位于金山区西南，东临金山区张堰镇、金山卫镇，是上海的西南门户和沪、杭、甬及舟山群岛经济区域中心。区域面积51平方公里，辖12个行政村，2个居民委员会，1个特色民居管委会，户籍人口3.1万人。</p><p>上海金山现代农业园区（廊下镇）先后获评国家卫生镇、全国农村旅游示范点、全国农产品加工业示范基地、国家级基本农田保护示范区、国家AAA级景区、全国休闲农业与乡村旅游五星级示范单位、中央党校浦东干部学院的现场教学点、国务院西部开发办西部展望计划现场教学点和市委组织部、市委党校的现场教学、上海品牌示范园区、上海产业园区品牌开发建设运营机构、上海品牌园区等称号和资质。</p><p>上海金山现代农业园区党委书记、管理中心主任沈文获评上海品牌园区优秀掌门人称号。</p>',
  extraVideo: 'mov-16-extra.mp4'
}, {
  id: 17,
  name: '上海漕河泾综合保税区',
  title: '上海品牌园区',
  type: '制造业型',
  description: '<p>上海漕河泾综合保税区作为漕河泾品牌的辐射区，对标高质量发展要求，依托综保区产业和技术优势，打造具有区域特色的高品质园区；依托国家促进综保区发展的政策优势，打造对外开放新高地；依托上海临港浦江国际科技城的区位优势，打造人文活力之城，使园区品牌化水平不断提升，国际化水平不断提升，品质化水平不断提升，产城化水平不断提升，将综合保税区建设成为新时代全面深化改革开放的新高地，打造成为上海品牌示范园区。</p><p>上海漕河泾综合保税区先后获评全国“安康杯”先进单位、上海市五一劳动奖状、上海市文明单位、上海跨境电子商务示范园区、上海十佳生产性服务业功能区示范园区、上海市五四红旗团支部、上海品牌园区、上海产业园区优秀开发建设运营机构、闵行区环境保护先进集体等称号和资质。</p>'
}, {
  id: 18,
  name: '上海松江经济技术开发区',
  title: '上海品牌园区',
  type: '制造业型',
  description: '<p>上海松江经济技术开发区位于松江新城东西两翼，总规划面积43.69平方公里。园区于1992年7月正式启动开发建设，是上海市首批市级开发区。2013年3月，经国务院批准，上海市松江工业区正式升级为国家级经济技术开发区。松江经济技术开发区在发展中注重产业布局的清晰规划，重视产业链上下游企业的组团招商，强调产业集聚带来的规模效应，着力打造工业强区的品牌特色，形成了以电子信息业、现代装备业、都市型工业为主的三大传统产业和以新能源、高端装备、生物医药、新一代信息技术、新材料、节能环保为主的战略性新兴产业齐头发展的新局势。</p><p>园区先后获评中国国际化营商环境建设十佳产业园区、上海市争创国家新型工业化产业基地（电子信息）、上海市生物医药制造业基地、深化非公企业工会改革试点园区、上海品牌园区、上海产业园区品牌开发建设运营机构等称号和资质。</p>'
}, {
  id: 19,
  name: '上海嘉定工业区',
  title: '上海品牌园区',
  type: '制造业型',
  description: '<p>上海嘉定工业区是1992年10月，经上海市人民政府批准设立的市级工业园区。作为嘉定全区经济发展重要增长极、产业转型主战场和科技创新核心区，以新能源汽车和汽车智能化、集成电路和物联网、高端医疗设备和精准医疗、智能制造和机器人“四大新兴产业”和总部经济、文化创意、电子商务、互联网产业“四大特色产业”为代表性主导产业。近年来，嘉定工业区深入实施创新驱动、转型发展战略，厚植发展优势，取得了较好的成果。截至2018年底，嘉定工业区已吸引来自世界40多个国家和地区的1500多家实体型企业落户，项目总投资累计超过400亿美元。</p><p>园区先后获评国家级留学人员创业园、张江国家自主创新示范区、国家级广告园区、国家电子商务产业基地、国家新型工业化产业示范基地，国家文化产业示范基地，全国社区侨务工作示范单位、上海品牌园区、上海产业园区品牌开发建设运营机构、上海市高端装备制造业标准化试点项目、市级创业孵化示范基地，上海市“两新”组织创先争优先进基层党组织、上海市食品安全示范街镇、上海市五星级“侨之家”、上海市高技能人才培养基地、上海市“四新”经济创新基地、上海市平安示范社区、上海市文明社区、节水型工业园区等称号和资质。</p>'
}, {
  id: 20,
  name: '上海金山工业区',
  title: '上海品牌园区',
  type: '制造业型',
  description: '<p>上海金山工业区于2003年9月经上海市人民政府批准成立的市级工业区。规划面积58平方公里，由新加坡裕廊顾问私人有限公司总体规划，是上海市政府重点支持发展、上海市打造杭州湾北岸先进制造业基地战略的主战场。金山工业区紧紧围绕“创新驱动发展、经济转型升级”总要求，以新型工业化、新型城镇化、信息化、绿色化“四化”联动发展, 着力构建“生产绿色化、生态绿色化、生活绿色化”的“三绿”发展模式，全面推进生态文明建设,不断推动园区产业升级、功能升级、生态升级，经济发展呈现出持续向好发展态势。始终坚持“两业”并举，着力提升传统产业能级的同时，加快发展战略性新兴产业，重点发展新一代信息技术、智能制造、生命健康、文化创意、新材料等主导产业，经济规模不断壮大，产业优势更加突出，配套功能日益完善，发展环境不断优化。</p><p>金山工业区先后获评国家新型工业化产业示范基地、国家生物医药产业基地、国家科技兴贸创新基地（生物医药）、国家绿色创意印刷示范园区、上海市新型工业化示范基地、上海市高新技术产业化新材料产业基地、上海金山日本企业产业园、上海品牌园区、上海产业园区优秀开发建设运营机构、上海市生物医药行业诚信企业、上海市知识产权示范园区、上海市“四新”经济创新基地、上海市文化创意产业园区、产业互联网生产性服务业功能区、市级创业孵化示范基地、海峡两岸青年创业基地、上海（金山）台湾中小企业产业园、诚信创建企业等称号和资质。</p>'
}, {
  id: 21,
  name: '上海国际汽车零部件产业园区',
  title: '上海品牌园区',
  type: '制造业型',
  description: '<p>上海国际汽车城零部件配套工业园区是安亭·上海国际汽车城的重要组成部分，创建于1998年。随着上海国际汽车城建设在安亭全面启动，2001年，批准为市级工业园区。园区规划面积8平方公里，是国内首家以汽车零部件为特色的产业基地，集聚了来自世界20多个国家和地区的200多家企业，其中规模以上工业企业114家，占比55%以上。2019年园区实现工业总产值821亿元，是区域工业经济的重要力量。</p><p>园区先后获评国家火炬上海安亭汽车零部件特色产业基地、火炬计划先进特色产业基地、上海市火炬计划实施二十周年先进集体、上海市文明单位、上海品牌园区、上海产业园区优秀开发建设运营机构、上海市节水型工业园区、上海市平安示范单位、上海市外经贸工作组织奖三等奖、上海市和谐劳动关系达标企业、上海市非公企业工会改革示范点、首批上海“四新”经济创新基地建设试点单位的称号和资质。</p>'
}, {
  id: 22,
  name: '上海宝山工业区',
  title: '上海品牌园区',
  type: '制造业型',
  description: '<p>上海宝山工业园区是2003年2月，由市政府批准设立的市级工业园区。位于上海北部，园区规划总面积20.54平方公里。宝山工业园区既是上海市战略性新兴产业的重要基地，也是城市与产业融合发展的功能实践区。园区将重点打造一个“北郊”品牌，建设“北上海生物医药产业园”、“上海国际邮轮产业园”、“新能源汽车创新园”、“北郊未来产业园”，发展邮轮配套、新能源汽车、智能硬件、生物医药产业。宝山工业园区在产业发展上坚持科技引领，瞄准战略性新兴产业，致力于将园区建设成为国家战略性新兴产业的承载地和代表先进制造业高端水平的产业基地。</p><p>园区先后获评国家新型工业化产业示范基地、上海先进装备产业基地、 上海能源装备产业基地、上海市循环经济试点园区、上海新材料产业基地、 上海智能装备产业基地、张江国家自主创新示范区宝山园、上海智慧园区试点建设单位、上海市四新经济创新基地、上海品牌园区、上海产业园区优秀开发建设运营机构上海品牌建设优秀园区等称号和资质。</p>'
}, {
  id: 23,
  name: '上海未来岛高新技术产业园区',
  title: '上海品牌园区',
  type: '制造业型',
  description: '<p>上海未来岛投资置业有限公司是上海未来岛高新技术产业园区的开发建设运营主体，成立于2001年11月，在原桃浦镇真建村的基础上建设而成。园区开发区域0.97平方公里。十八年来，园区经济发展、产业能级、经济总量、配套功能、品牌效应都发生了历史性变化，引进施耐德、福克斯波罗、科尼起重机、索雷博、密特印制等一批国内外总部型知名企业，重点培育和发展高新电器、先进制造、尖端电子等三大支柱产业，打造智能制造、新材料、新文化新三大支柱产业，现有产税注册企业641家，职工1.25万人。</p><p>园区先后获评上海市文明单位、上海市著名商标、上海市“五好”基层党组织、上海市知名品牌创建示范区、上海品牌园区、上海品牌建设优秀园区等称号和资质。</p>'
}, {
  id: 24,
  name: '上海M50文化创意园区',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海M50文化创意园区隶属于东方集团，是以艺术、创意、生活为核心价值的文化创意品牌。是上海最早的创意产业集聚区之一，也是目前上海最具规模和影响力的创意产业园区之一。园区位于普陀区苏州河南岸莫干山路50号，原址—信和纱厂，园区占地面积约41亩，拥有自上世纪30年代至90年代各个历史时期的工业建筑约43000平方米。园区引进了包括英国、法国、意大利等在内的20多个国家和地区的约140个艺术家工作室以及画廊、各类设计机构，涵盖艺术、设计、创意、文化各领域。近几年，M50艺术季、M50大学生创业市集、M50中外少儿艺术大赛已逐渐成为M50品牌建设未来发展的的核心板块。M50这个蜕变于传统工业、成长于民族工业老厂房、发展于上海新一轮产业结构调整时期的品牌，经过十几年的积累，正逐步成为“艺术、创意、生活”的代名词。</p><p>园区先后获评全国工业旅游示范点、上海市首批创意产业集聚区、上海十大优秀创意产业集聚区、上海首批文化产业园区、AAA级旅游景点、上海名牌、上海市著名商标、上海品牌园区、上海产业园区优秀开发建设运营机构等称号和资质。</p>'
}, {
  id: 25,
  name: '上海800秀文化创意产业园区',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海800秀文化创意产业园区是由上海静工（集团）有限公司与上海电气集团共鑫投资管理有限公司双方共同出资组建，位于常德路800号，原为上海人民电机厂，占地20余亩，建筑面积30000平方米，总投资额达1亿元人民币（其中静工集团占80%；电气共鑫公司占20%）。八佰秀公司力争把“800秀”创意产业集聚区建设成为立意高、具有地标性的一流创意产业园区。园区紧贴地铁7号线，独一无二的多功能秀场与南京西路CBD交相辉映，成功吸引了大批国际知名的设计、公关以及时尚传媒企业入驻，使“800秀”成为上海国际时尚品牌集聚和时尚产业链延伸的重要一环。</p><p>园区先后获评长三角文化产业产业优秀集聚区、上海文化创意产业示范园区、上海市著名商标、上海市企业文化建设示范基地、首批上海市创意产业集聚区授牌的示范性园区、上海市文明单位、上海品牌园区、上海产业园区优秀开发建设运营机构、上海工人先锋号、上海时装周制定时尚秀场等称号和资质。并已获得ISO9001和IS14000管理体系专业认证。</p>'
}, {
  id: 26,
  name: '上海南翔智地企业总部园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海南翔智地企业总部园是由原上海机床电器厂、上海东风制药厂、上海申兴制药厂和上海永红煤矿机械厂等老厂房重新改建而成。总占地面积1.1平方公里（1663.5亩），总建筑面积85万平方米。鉴于改建时保留原有老厂房的外立面风格，同时增加了空间感和设计感，使得园区内的建筑群样式各异，文艺气息扑面而来。南翔智地的发展经历过几个阶段。第一个阶段是划分出一个行政意义上的南翔智地企业总部园的概念。第二个阶段是聚集了几百家企业，正好时下也提出了“大众创业、万众创新”的概念。第三个阶段，A区、B区、C区集聚了1600多家企业，到达了一个社区的概念。其在上海旧厂区改造中的重要性示范性由此可以窥一斑而见全豹。</p><p>园区先后获评国家文化产业示范基地、上海市文化创意产业示范基地、上海市知识产权示范园区、上海市创业孵化示范基地、上海品牌园区、上海产业园区优秀开发建设运营机构、本市首批上海生产性服务业功能区、上海市文化产业园区、上海市企业总部基地、上海市民营经济总部集聚区、院士专家服务中心、嘉定区现代服务业示范企业、嘉定区现代服务业重点发展扶持项目等称号和资质。</p>'
}, {
  id: 27,
  name: '上海莘泽创业园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海莘泽创业园的运营主体是上海莘泽创业投资管理股份有限公司，成立于2010。2015年12月，莘泽创业成为国内第一家在新三板挂牌的孵化器运营公司。公司致力于“同小微企业共成长”，为创业小微企业提供基于创业苗圃、孵化器和加速器的市场资源整合服务、科技金融顾问服务以及风险投资等服务，行业聚焦集成电路、人工智能、生物医药、移动互联网、智能制造等多个高新技术产业领域。</p><p>莘泽创业园先后获评国家级科技企业孵化器、国家小型微型企业创业创新示范基地、国家中小企业公共服务示范平台、全国创业孵化示范基地、国家科技创业孵化链条建设示范单位、全国第一家新三板上市的孵化器运营单位、上海市科技创业孵化贡献奖、市科技创新创业服务先进集体、上海科技创业导师工作室、上海市创业孵化示范基地、上海市中小企业公共服务机构、上海市信息服务产业基地、上海市高新技术企业、上海市张江高科技园区科技创业孵化基地等称号和资质。</p>',
  extraContent: '上海莘泽创业投资管理股份有限公司董事长曲奕获评上海品牌园区优秀掌门人称号。'
}, {
  id: 28,
  name: '上海康桥先进制造业技术创业园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海康桥先进制造技术创业园有限公司是上海康桥先进制造技术创业园的运营主体。于2006年由上海浦东康桥（集团）公司、上海市科技创业中心及上海张江火炬创业投资有限公司共同投资组建，注册资本12，128万元。作为上海“一区一新”、“市区联动”具有鲜明先进制造技术特色的国家级科技企业孵化器，园区占地10万余方。是上海市高投资高规模的先进制造技术领域的创业园区，集政府引领、资本驱动、国资建设管理的多层优势，力争成为上海、浦东、张江智能制造.AI先进技术产业的孵化源头，成长加速器和产业聚集区。</p><p>上海康桥先进制造技术创业园先后获评国家级科技企业孵化器、国家中小企业公共服务示范平台、国家小型微型企业创业创新基地、上海首批市级创业孵化示范基地、上海市“四新”经济产业创新基地、上海市青年创业基地，上海市科技企业孵化器30年优秀孵化器、大学生创业基地，留学生创业基地、上海品牌园区等称号和资质。</p>'
}, {
  id: 29,
  name: '上海长宁德必易园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海长宁德必易园于2008年签约改造，2010年7月1日开园，是德必“易园”系列的第一个园区，雄踞长宁区核心区域，中山公园商圈黄金地带。长宁德必易园为上海多媒体产业园分园，以多媒体与文化创意产业为主业态，致力于为文创科创企业提供发展平台和服务。作为多媒体产业园，其中文创、科创企业集聚度达到75%，文创企业入住率占园区可出租面积的92%，包括高新技术、科技服务、新媒体、网络、多媒体、设计、广告等企业。园内配套服务面积达2729平方米，绿化覆盖率40%。近年来吸引了诸多客户前来发展。园区内知名企业有美团点评、上海爱观视觉科技有限公司等。</p><p>园区先后获评长三角文化创意产业年度大奖、上海市文化创意产业示范园区、上海市文化产业园区、优秀文化创意产业集聚区、上海公益基地、上海市巾帼文明岗、长宁区青年创业见习基地、长宁区文明单位等称号和资质。</p>'
}, {
  id: 30,
  name: '上海天地软件园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海天地软件园的开发建设运营机构是上海天地软件创业园有限公司，成立于2004年，上级单位是普陀区长征镇人民政府，2019年总税收16.29亿元，经济贡献度在普陀科技园区中排名前列。园区积极响应上海打响四大品牌的战略目标，以大数据及互联网+、智能制造及机器人、动漫游戏、文化影视、金融科技为五大支柱产业。园区入驻企业超过400家，注册企业2000多家，就业人员近2万人，共有87家高新技术企业，14家新三板挂牌企业、44家科技小巨人企业，22家市区企业技术中心。</p><p>园区先后获评国家文化产业示范基地、上海市软件产业基地、上海市电子商务示范园区、上海市软件出口（创新）园区、上海市文化创意产业示范园区、上海市明星软件园（特色型）、上海品牌园区、上海市创业孵化示范基地、上海市中小企业服务机构、上海市著名商标、上海企业文化创新品牌等称号和资质。</p>'
}, {
  id: 31,
  name: '上海长江软件园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海长江软件园是由上海市、宝山区政府认定的高科技软件产业园区，由原先的钢贸市场转型为产业园区，总面积约7万平方米，入驻企业220多家，入驻率 85%，吸纳就业人员近3000人。主导产业为智慧软件、北斗导航、互联网、人工智能、人力资源、低空安防、生命科学。聚焦“国际化、专业化、标准化、品牌化”的品牌战略，不断创新管理机制，促进科技成果创新转化应用，深挖产业的品牌价值。目前已经集聚了一批以北斗导航、智慧软件、互联网、人工智能、新材料、人力资源、低空安防、无人机、生物医药、生命科学等行业为主的高科技产业群。</p><p>园区先后获评国家级科技企业孵化器、上海市文化创意产业园区、上海市科技企业孵化器、上海版权示范园区（基地）、上海市创业孵化示范基地、上海市知名品牌示范区、上海市知识产权试点园区、上海品牌园区内、上海市互联网企业党建工作创新基地等称号和资质。</p>'
}, {
  id: 32,
  name: '上海徐汇软件园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海徐汇软件园的开发建设运营主体是上海徐汇软件发展有限公司，现为上海枫林生命健康产业发展（集团）有限公司（国企）全资子公司。园区成立二十一年来，一直专注于高科技企业孵化，专注于人工智能+生命健康产业集聚，先后培育了携程旅行网、巨人网络、好未来等14家国内知名的上市公司，年服务企业数达到350余家。“徐软”服务理念和品牌特色在业内广为流传，形成了中国高科技园区服务的事实标准，极大促进了整个行业的跨越式发展，园区因此获得了创业者、企业、协会、政府的广泛认可。</p><p>园区先后获评国家火炬计划软件产业基地、国家数字媒体产业化基地、全国先进社会组织、国家小型微型企业创业创新示范基地、上海市软件产业基地、上海创意产业集聚区、上海市文化产业园区、上海版权示范园区、上海市模范职工之家、上海市中小企业服务机构、上海市青年创业见习基地、上海市软件出口（创新）园区、上海市信息服务产业基地、上海市明星软件园、上海市创业孵化示范基地、上海市诚信创建企业、上海市平安示范单位、上海市工人先锋号、徐汇区先进基层党组织、徐汇区三八红旗集体、徐汇留学人员创业园等称号和资质。</p>'
}, {
  id: 33,
  name: '上海双创产业园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海双创文化创意产业园于1997年从纺织制造企业转型而来，地处上海浦东新区陆家嘴金融贸易中心峨山路613号，建筑面积14528㎡。现园区入驻企业为131家。</p><p>通过多年的实践和经营，上海双创产业园打造了以 “双创”（创新+创业）为载体的文化创意产业集聚区。成功拓展了创业、开业、就业“三业并举”的创新路子，园区不断从新内容、新业态、新模式三方面创新发展，大面积培育和孵化创业创意群体，广角度、大纵观地推进大学生“四创”资源（即创意、创新、创智、创造）的集成。</p><p>上海双创产业园先后获评国家级小型微型企业创业创新示范基地，上海市文明单位、上海市文化创意产业园区、上海品牌园区等称号和资质。</p>'
}, {
  id: 34,
  name: '上海中广国际广告创意产业园',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海中广国际广告创意产业园成立于2007年，园区打造以广告产业为核心，通过产业链的高度聚集来集约资源，推进中国知名广告企业的扩张与品牌提升，是国内第一批具有规模性的广告创意产业园。园区拥有国家广告产业园区、国家电子商务示范基地、国家文化产业示范基地三大国家级品牌，是全国以广告产业为代表的“泛文化”产业集聚地之一。园区全力为企业提供从注册落户、资金扶持、人才优惠、金融服务、项目申报等一条龙服务模式，形成优质的发展环境，推动不同阶段的企业在园区不断发展壮大。</p><p>园区先后获评国家广告产业园、国家文化产业示范基地、国家电子商务示范基地、上海文化产业园区、上海市知名品牌示范区、上海市著名商标、上海市文化创意产业园区、上海市文化创意产业示范园区、上海市创意孵化示范基地、中国广告40周年特别贡献奖、上海品牌园区等称号和资质。</p>'
}, {
  id: 35,
  name: '上海优族173文化创意产业园区',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海优族173文化创意产业园区坐落于上海市虹口区邯郸路173号，毗邻复旦、同济、上财等多所高度科研院校，由上海旭捷实业投资有限公司斥资打造运营。运营管理方有效利用园区区位优势，认真贯彻品牌战略实施，突显 “优族”品牌的独特性，以现代IT软件产业为导向，通过科技创新、产品创新、服务创新，致力于营造IT软件的设计、研发空间和文化环境，促进科技成果的转化与生产化，以技术创新推动经济和社会发展，形成一个集知识、信息和国际人才汇聚的多功能现代化的创意产业聚集区。</p><p>园区先后获评上海市创意产业集聚区、上海市创意产业示范单位、上海市文化创意产业园区、上海市文明单位、上海品牌园区、上海品牌建设优秀园区、上海市知名品牌创建示范区、上海市大学生社会实践基地、上海青年创业见习基地、上海市诚信创建企业、上海市劳动关系和谐示范工业园区、虹口区文明单位、虹口区先进企业、虹口区非公有制经济人士理想信念教育实践基地等称号和资质。</p>'
}, {
  id: 36,
  name: '上海国际时尚中心',
  title: '上海品牌园区',
  type: '服务业型',
  description: '<p>上海国际时尚中心是东方国际（集团）旗下全资子公司，原为 1921 年日商开办的裕丰纱厂，解放后为国棉十七厂，2008 年立项产业升级转型为上海国际时尚中心，投资总额 5.6亿，地处黄浦江北岸，位于杨树浦路 2866 号，占地面积 89562 平方米。园区内特有的锯齿形厂房为国家历史保护建筑。2015年成为国家 4A级旅游景区，上榜国家十大工业遗产旅游基地，2016年挂牌上海市文化创意园区。已通过ISO9001 和ISO14001 质量标准体系认证。园区整合人文及公共价值将创意设计、旅游、展演和服务支持等多元功能相互联动，开启了时尚功能高度集约，互为价值链的文旅商综合体。</p><p>园区先后获评国家工业遗产旅游基地、国家AAAA级旅游景区、上海市文明单位、全国纺织行业人才建设先进单位、上海全球新品首发人气榜单、能源消费总量和能耗强度“双控”指标优异单位、上海艺术商圈合作商企业、上海市智慧城市建设项目入围奖、上海最美旅游厕所、上海市一星级诚信创建企业等称号和资质。</p>'
}, {
  id: 37,
  name: '上海青浦现代农业园区',
  title: '上海品牌园区',
  type: '现代农业',
  description: '<p>上海青浦现代农业园区发展有限公司是上海青浦现代农业园区的运营主体。位于我国老一辈无产阶级革命家陈云同志的故乡——青浦练塘镇，是上海市率先启动的4家市级农业园区之一。于1999年3月始建于香花桥镇，2002年10月，因全区产业布局调整，西移扩建至练塘镇蒸淀地区，规划面积17.07平方公里，现有耕地面积9.6平方公里。园区坚持以“科技兴园，产业强园，生态建园，创新治园”的品牌战略，与各大高校科研院所合作开展生态农业、优质稻米、特色菌菇、有机蔬菜、名优浆果等各个领域的科学研究，推动产业标准化建设和运营，积极推进“优质种源、品牌水稻、设施菜田、特色林果、特种水产、生态园林”六大产业片，形成了“一粒米、一只菇、一棵菜、一枚果”的四大特色品牌。引导农业向“生产、生态、生活”方向发展，促进农业增效、农民增收。努力打造一个集生态、休闲、观光、教育于一体的特色现代农业园区，如蓝莓文化体验、蛙稻生态种养、林下食用菌栽培、科教研基地等，这些各具特色的产业基地已成为青浦农业经济发展的新亮点。</p><p>上海青浦现代农业园区先后获评全国科普教育基地、国家级科技特派员创业基地、全国科普惠农兴村先进单位、上海市科普惠农兴村示范基地、上海市科普体验实践基地、上海市院士专家服务中心、上海市知名品牌示范区、上海品牌园区、青浦区学生社会实践基地。</p>',
  extraContent: '上海青浦现代农业园区发展有限公司董事长丁国平获评上海品牌园区优秀掌门人称号。',
  extraVideo: 'mov-37-extra.mp4'
}, {
  id: 38,
  name: '上海卓维700文化创意产业园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海卓维700文化创意产业园隶属东方国际（集团）有限公司，重点引入艺术摄影、影视后期、广告传媒等特色鲜明且具有行业影响力的时尚文化创意主导产业入驻。园区不断探索园区平台建设途径和内容，优化入驻园区产业结构，提供平台式创新服务，持续宣传卓维700园区品牌，为创建上海品牌园区不懈努力。</p><p>卓维700园区先后获评首批上海市创意产业集聚区、上海市文化产业园区、上海市5星级诚信企业、上海市文明单位，上海市文化创意产业园区、上海品牌园区、上海品牌特色园区等称号和资质。</p>'
}, {
  id: 39,
  name: '上海市移动互联网产业基地海宝示范园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海市移动互联网产业基地海宝示范园（以下简称“海宝园”）位于宝山区沪太路4361号，一期占地面积80亩，建筑面积3万平方米。园区前身海宝玻璃厂，始建于1987年，由于高能耗、高污染被关停，一直闲置。2009年7月，老厂房被改造为海宝园。作为上海首个移动互联网创业孵化园，海宝园以此作为“金字招牌”，围绕移动互联网芯片、终端、传输、软件、平台、应用六大重点领域，以软件和信息技术服务、专业技术服务、研究和试验发展、科技推广和应用服务、商务服务为主导产业，集聚产业孵化、合作对接、应用推广、人才服务等服务，重点引进应用标杆企业，支持创新型企业创业，打造上海市移动互联网产业创新先导区、高端人才汇聚区和应用服务示范区。2018年，园区企业创造了超过20亿的营收，缴纳税收达1.8亿元。</p><p>园区先后获评中国中小企业创新服务先进园区、长三角地区中小企业上海服务外包基地、上海市高新技术产业化（信息和软件服务业）基地、上海品牌建设优秀园区、上海品牌特色园区、上海移动互联网产业发展联盟发起单位、上海工业旅游景点服务质量达标单位、宝山区2019年度经济工作卓越园区等称号和资质。</p>'
}, {
  id: 40,
  name: '上海尚之坊文创园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海尚之坊是上海市级文创园，创立于2009年，一期以工业厂房改建创意办公及BLOCK街区、二期以30栋独栋商务办公楼宇及服务公寓等相关配套设施，打造总部楼宇经济与时尚创意产业的共享服务平台，融合“总部经济、众创孵化、文化创意、智能科技、社区生活”于一体。现园区已完成80%入住率，并引进150多家企业；2020年二期新建拥有独立产权、主力面积在“1800㎡-4200㎡”多栋独栋办公、LOFT商务楼宇、及服务式人才公寓等已正式对外招商出售。园区以多元化品牌的发展需求为导向，以国际视野整合产业链上下游权威资源，建设集约化专业服务体系，依托上海这座时尚之都，聚集企业品牌设计、创意空间、研发平台供给，打造园区品牌生态圈，园区主导产业涉及计算机软件开发、微电子技术、多媒体影视技术、文化创意产业、新能源技术开发等。</p><p>园区先后获评上海市级文化创意产业园区、上海市四新经济创新基地、上海市知名品牌创建示范区、上海品牌特色园区、上海市科技企业孵化器、上海市梦创工坊青年中心、上海市中小企业服务机构上海市创业孵化示范基地、上海市创业者见习基地、青浦众创空间、青浦区众创中心等称号和资质。</p>'
}, {
  id: 41,
  name: '上海E3131电子商务创新园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海E3131电子商务创新园是在2009年3月，由来自互联网、电子商务、传媒、旅游、家居、健康等产业富有创业经验的企业家及投资人组成的创始人团队，以原嘉定真新街道老厂房改建创建。e3131不仅是物理空间上的园区，更是具备了电子商务项目投资与运作经验、产业地产项目运营经验、产业资源以及资本优势的，电子商务产业链线上到线下的整合服务机构。主导产业为电子商务、文化创业产业。未来，e3131将打造诚信 B2C线下聚合大本营，建设一流的电子商务产业链孵化基地。</p><p>园区先后获评上海市创意产业示范集聚区、上海市电子商务示范园区、上海市信息产业服务基地、上海市知名品牌示范区、上海品牌特色园区、上海市工业旅游景点、上海市4A“梦创工坊”青年中心、上海市科技企业孵化器、上海市创业孵化示范基地等称号和资质。</p>'
}, {
  id: 42,
  name: '上海创异工房文化产业园区',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海创异工房文创园区位于上海市松江区沈砖公路6000号，由上海佳利特实业有限公司投资建设与管理，园区占地150亩，已建建筑面积64000平米2009年7月，园区建成并投入使用，依托现代创意的建筑风貌结合苏州古代原始建筑，坚持以人为本的运营方针，提供细致与人性化服务，始终适应和超越入驻企业和员工对服务的要求和期待。园区始终将产业升级和转型作为出发点，吸引文化创意、科技创新、工业服务类型为主的优秀企业入驻。目前园区入驻企业80余家，员工1200余人。</p><p>园区先后获评上海市文化创意产业示范园区、上海市知名品牌创建示范区、、上海品牌特色园区、松江区服务业创新发展示范基地、松江区小型微型创业示范基地、松江区优秀产业园区等称号和资质。</p>'
}, {
  id: 43,
  name: '上海新华文化科技园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海新华文化科技园成立于2009年，地处上海市静安区，占地面积14,141平方米，一期建筑面积达到5.1万平方米。是由上海新华发行集团、上海杨浦科技创业中心两家单位共同出资建立的新型文化创意产业园区。新华园始终围绕“科技与文化结合，打造一流创新创业科技园区”品牌发展战略，经过十多年的建设与运营发展，累计引进企业近1000家，形成了以文化创意数字内容制作、数字出版、创意设计、动漫为主的主导产业，构建了“高端制作、人才培育、技术研发、产业集聚”的文化、科技产业运营体系，吸引了一大批优秀的动漫、电竞、影视、新媒体等文创类和科创类企业落户。</p><p>园区先后获评国家级科技企业孵化、国家中小企业公共服务示范平台、上海市文化产业园区、上海文化科技企业孵化器、上海市信息服务产业基地、上海文化科技企业创业苗圃、上海市版权示范园区、上海品牌特色园区、上海市文明单位、上海市五一劳动奖状、上海市创新创业服务体系建设专业化引导项目立项、上海市市级青年中心、上报集团改革创新案例“优秀案例奖”、上海市五四红旗团支部、上海科技创业导师工作室试点单位、上海梦创工坊、上海影视拍摄推荐取景地、上海诚信创建企业、AAAA级青年中心、静安创业联盟、静安区星级优秀园区等称号和资质。</p>'
}, {
  id: 44,
  name: '上海莲谷新兴科技产业园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海莲谷新兴科技产业园是由莲谷投资管理（上海）有限公司运营的专业孵化器（以下简称莲谷科技园），专业方向包括智能制造和生物医疗。莲谷科技园致力于打造以“莲智、莲创、莲彩”为主题的品牌战略。“莲智”，促进区域内智能制造产业发展和联合创新；“莲创”，为在孵企业及周边高校等提供展示项目、展示企业、对接资源的平台；“莲彩”，举办丰富多彩的创业活动，助力企业开拓创新。</p><p>莲谷科技园先后获评上海市科技企业孵化器、上海品牌特色园区、闵行区创业孵化示范基地的称号和资质。</p>'
}, {
  id: 45,
  name: '上海尚街Loft品牌园区（中区）',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海尚街Loft时尚生活园由东方国际（集团）有限公司旗下三级企业上海尚界投资有限公司运营管理，地址位于徐汇区嘉善路508号，建筑面积41217平方米。园区前身为三枪老式工业厂房，2007 年，尚界斥资14000万元对园区进行整体规划布局,并力邀意大利名设计师菲利普.戈比尼对园区进行设计，使园区整体物理空间呈现出具有国际、时尚、创意、生活特色的艺术园区。园区以“时尚生活产业及设计师高定”为核心定位。园区现入驻业态主要分为时尚文创（传媒+文化）、时尚设计、时尚生活、时尚教育、时尚贸易、时尚餐饮六大类，现入驻企业近122家，含百威、欧莱雅等500强著名企业。为打造夯实尚街Loft品牌形象，园区不断探索新模式、新路径，加强优化营商环境，提升园区服务能级，以企业发展需求为根本出发点，不断完善服务平台的功能和内容，为创建具有尚街品牌特色园区而努力奋斗。园区现入驻业态主要分为时尚文创（传媒+文化）、时尚设计、时尚生活、时尚教育、时尚贸易、时尚餐饮六大类，现入驻企业近122家，含百威、欧莱雅等500强著名企业。</p><p>园区先后获评国家级纺织服装创意设计试点平台、上海市文化创意产业园区、上海品牌特色园区等称号和资质。</p>'
}, {
  id: 46,
  name: '上海盘古创业园区',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海盘古创业园区的开发运营主体是上海融经互联网科技发展有限公司。上海盘古创业园区作为“双创产业综合服务平台”，打造“安全数据产业赋能平台”，以数据运营、人工智能、军民融合、医疗器械为主导产业。在不同领域建立产业数据平台，赋能传统产业升级改造。陆家嘴基地位于上海浦东新区浦电路57号三楼，是陆家嘴核心区，总面积6500平方，由创新区、公共区和产业区组成。为各地方政府产业招商，帮助政府设立产业联络点、筛选国内外创新项目、对接资本人才等，快速实现产业落地。构建全球科技项目展示和交流中心，发挥陆家嘴人才、资本、信息、与地域优势。以“创新+创投”服务于长三角一体化发展，搭起园区、产业、资本的服务之桥，打造全国“双创产业综合服务平台”典范。已有量化交易、金融安全、茶叶，大消费，农业土壤监测、生态环境，深耕细分行业的创新项目孵化投资及产业化，实现共建、共享、共赢。盘古创业已经投资孵化了超过百个创新型项目。</p><p>园区先后获评上海市科创技术委员会专业化、上海市科创技术委员会品牌化、上海品牌特色园区、上海市共青团梦工坊青年中心、张江国家高新示范区众创空间、浦东新区科经委创新型孵化器、浦东新区孵化联盟成员等称号和资质。</p>'
}, {
  id: 47,
  name: '上海移动智地',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海移动智地由上海锐嘉科实业有限公司开发运营管理。园区主导产业为移动互联网、智能智造。是集智慧生态、创新服务、科研开发、资本驱动、加速孵化于一体的移动互联网产业总部基地。园区总占地面积330亩、总建筑面积39万平米。园区依托完善的基础设施、优良的生态环境，凭借自身的专业化服务能力，打造了强大的公共服务和产业技术支撑平台，为园区企业在移动智地和青浦落地扎根、产业关联发展与转型提供优选之地与精准服务，促进基地产业集聚发展和产业倍速发展。全力以赴引进项目落户，稳扎稳打助力青浦发展新动能，科创兴业，深挖拓潜，目前园区落户企业达500余家。</p><p>园区先后获评上海市挂牌企业总部试点基地，上海市生产性服务业功能区、上海市创业孵化示范基地、上海市中小企业服务机构、上海市信息服务产业基地、上海市文化创意产业园、上海市知名品牌创建示范区、上海品牌特色园区、院士专家服务中心、青浦区优秀平台企业、青浦区信用管理试点培育园区、青浦区商标品牌指导站、青浦区科普基地等称号和资质。</p>'
}, {
  id: 48,
  name: '上海衍禧堂创业园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海衍禧堂创业园的运营主体是上海衍禧堂企业管理有限公司。成立于2008年，有着十多年的医疗孵化经验，以“孵化服务+天使投资+创业培育+创业社区”为运营模式，孵化企业100多家，辐射企业达300多家，目前已毕业的企业20多家。衍禧堂联手35位来自生物医疗、投资、研发机构的追梦人共同发起“HiMed交流社区”，搭建张江医疗创新创业交流合作对接平台。平台整合各类医疗健康行业资源，为创业者提供低成本、便利化的创业平台。在张江中区随着国际化项目的不断入驻，形成了一个集合多国元素，专注医疗，社群围绕，品牌聚集的“HiMed国际医创中心”。</p><p>园区先后获评国家级生物医药科技孵化器、张江高科技园区专业孵化器、上海市孵化器30 周年荣获优秀孵化器奖、上海品牌特色园区等称号和资质。</p>'
}, {
  id: 49,
  name: '上海浦东软件园三林园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海浦东软件园三林园的开发运营主体是上海浦东软件园三林园发展有限公司，成立于2014年。其开发建设的上海浦东三林园，成为浦东软件园继郭守敬园、祖冲之园、昆山园、三林世博园之后投入运营的第五大园区。承担上海浦东软件园三林园区规划建设以及其与三林世博园的经营管理和产业发展等重要任务。新建的三林园区总规划面积50万平方米，总投资50亿元。整体建成后将吸引超过200家科技企业入驻，将成为上海市软件和信息服务业新的产业基地及集聚区。上海浦东软件园三林园位于浦东新区三林镇懿德地区，该板块处于整个上海版图的中心腹地，距离前滩仅5公里，且是浦东区域内一个难得的、完整的产业集中区块。</p><p>园区先后获评中国数字经济与软件服务业最佳服务平台奖、上海市信息服务产业基地（（国产基础软硬件方向特色基地）、上海品牌特色园区等称号和资质。</p>'
}, {
  id: 50,
  name: '上海万香国际创新港',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海万香国际创新港是2016年由上海优雅仓储有限公司自有厂房转型成立的跨境电子商务园区。主园位于浦东新区航头镇航都路18号，占地40000余㎡，建筑面积53000余㎡。2016年正式投入运营使用。园区围绕发展定位，确立发展目标，借助上海区位、外贸优势和自贸区政策优势，在数字科技生态构建、园区标准化推进、跨境电商孵化体系建立、公共服务平台建设等方面独树一帜，逐步成为立足上海浦东自贸区，服务辐射长三角的，以围绕着以电商跨境服务为特色的数字科技产业集聚区。</p><p>园区先后获评上海市电子商务示范园区、上海市中小企业服务机构、上海品牌特色园区、浦东新区电子商务示范园区、浦东新区科技企业孵化器、浦东新区文化创意园区等称号和资质。</p>'
}, {
  id: 51,
  name: '上海半岛湾时尚文化创意产业园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海半岛湾时尚文化创意产业园位于虹口区溧阳路735号，为上海辛克实验机厂原址。园区地处SMV音乐谷核心位置，是上海音乐谷国家音乐产业基地的项目之一。</p><p>园区以时尚文化产业为发展导向，自2013年4月开园以来，半岛湾获得了上海市“四新”经济创新基地、上海市文化创意产业园区、上海市工业旅游景点等诸多荣誉。举办了活动约300余场，吸引参观人次超50万人，获得了新民晚报、解放日报、虹口报、东方网等几十家媒体报道。半岛湾与音乐谷地块的开发及北外滩科技创新产业的建设形成互动，使之成为商业、文化、旅游、科技为一体的多媒体示范基地。园区先后获评上海市“四新”经济创新基地、上海市文化创意产业园区、上海市工业旅游景点、上海品牌特色园区、虹口区创业孵化基地、 虹口区科技创新创业服务驿站等称号和资质。</p>'
}, {
  id: 52,
  name: '上海漕河泾德必易园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海漕河泾德必易园坐落于黄浦江现代高端服务业发展轴线的南端，与上海南站立体衔接，轨道交通1、3号线及16条公交路线可到达本项目。邻近沪闵高架、中环高架、龙耀路隧道，畅行上海各城区，迅达长三角各城市据点。主导产业为新媒体为代表的高端创意产业，如新媒体及互联网科技企业，具有较高的产业集聚度。小而精致的模式、独领风骚的优势技术及特立独行的思维是其取胜的三大特色。</p><p>园区先后获评上海市文化创意产业示范园区、上海品牌特色园区、华东理工-德必易园艺术硕士实践基地等称号和资质。</p>'
}, {
  id: 53,
  name: '上海SOHO丽园文化产业园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海SOHO丽园文化产业园（以下简称“SOHO丽园”）位于上海市黄浦区丽园路501号，原系上汽集团上海制动器厂20世纪70年代的老厂房，经保护性改建而成。园区由上海八号桥投资管理（集团）有限公司进行整体规划、建设、招商和运营。主导产业为建筑设计、网络信息业、软件与计算机服务业、咨询服务业、广告及会展服务、文化创意相关产业。属于上海市首个“一区多园”组团式发展的创意产业集群区—“江南智造”范畴。</p><p>园区先后获评上海市创意产业集聚区、上海市文化创意产业园区、上海品牌特色园区、上海市工业旅游景点服务质量达标单位、黄浦区创业孵化示范基地等称号和资质。</p>'
}, {
  id: 54,
  name: '上海大树下新媒体文创园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海大树下新媒体文创园的主导产业为文创产业中的新媒体板块，涵盖广告设计、建筑设计、艺术设计、内容营销、VR、AR 等。致力于新型的企业关系(Relations)的搭建，通过打造令人享受(Enjoyment)的办公环境(Environment) ，赋予每个企业思考(Thinking)的力量，以“开放、聚合、生态、智慧”的理念，从城市更新、空间创意、资源共享、平台运营，四个维度出发，链接企业发展核心，搭建重度垂直产业生态圈和企业成长平台。让每家企业，每个人，分享智慧成果，感受世界交互的力量。</p><p>园区先后获评中国文化创意产业十大新锐园区、十大城市更新资产运营机构、上海品牌特色园区、上海市优秀文化创意园区、闵行区特色文化创意园区、闵行区创业孵化示范基地等称号和资质。</p>'
}, {
  id: 55,
  name: '上海1876老站文创园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海1876老站文创园位于汶水东路和广纪路交叉口，地理位置得天独厚。它的前身原为70年代老仓库，毗邻淞沪铁路始发站—“江湾站”旧址。设计和改建以旧址的独特地理优势、历史背景和悠久文化底蕴。主导产业为文化传媒、创意平台、新型科技等。自建成后，入驻率一直保持在95%以上。园区发展业绩突出、社会效益良好、经济效益显著，已成为虹口区新经济常态下行业的新地标。</p><p>园区先后获评上海市文化创意产业园区、上海市文化创意产业示范园、上海市文明单位、上海品牌特色园区、上海市先进企业、上海市和谐劳动关系达标企业、上海市治安安全合格单位、安全生产管理先进单位等称号和资质。</p>'
}, {
  id: 56,
  name: '上海幸福里文创园',
  title: '上海品牌特色园区',
  type: '服务业型',
  description: '<p>上海幸福里文创园是由一座由老厂房更新改造成的精品文创园区。集创意办公、文化艺术、商业休闲于一体。主导产业为文化创意、设计策划、餐饮服务、零售。传承海派文化“海纳百川 兼容并蓄”的精神，以现代手法及前卫设计精心打磨，既体现现代艺术的质感，又完美平衡了社区与商业的关系。贯彻社区友好型理念，秉承打造“社区客厅”的项目思路，与周边社区良性互动、和谐共生。幸福里的建成，是将无效资产变成高效资产的项目改造典范之作，是城市更新发展的又一里程碑。并得到了长宁区政府及各相关部门领导的大力支持，一经推出市场反响热烈，被誉为长宁的“新天地”，并迅速成长为长宁文创项目名片和时尚文艺地标。</p><p>园区先后获评城市生活大赏年度精致生活地标、上海虹桥时尚创意产业集聚区、年度时尚地标、长宁区首批夜间经济示范点、长宁区创业琅琊榜等称号和资质。</p>'
}];